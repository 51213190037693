// IncomeSourceItem.js

import React from 'react';
import { motion } from 'framer-motion';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';

const IncomeSourceItem = ({ name, incomeAmount, expenseAmount, onSelect, isSelected }) => (
  <motion.div
    className={`flex justify-between items-center py-4 px-5 border border-gray-200 rounded-lg shadow-sm cursor-pointer ${
      isSelected ? 'bg-blue-50 border-blue-500' : ''
    }`}
    onClick={onSelect}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3 }}
    whileHover={{ 
      scale: 1.02, 
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      transition: { duration: 0.2 }
    }}
    whileTap={{ scale: 0.98 }}
    layout
  >
    <motion.span 
      className="font-semibold text-gray-900"
      whileHover={{ x: 5 }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      {name}
    </motion.span>
    <div className="flex items-center space-x-4">
      {/* Income Amount */}
      <motion.span 
        className="flex items-center text-green-600 font-semibold"
        whileHover={{ scale: 1.1 }}
        transition={{ type: 'spring', stiffness: 400, damping: 10 }}
      >
         <ArrowUpRight size={16} /> ${incomeAmount.toLocaleString()}
      </motion.span>
      
      {/* Expense Amount */}
      <motion.span 
        className="flex items-center text-red-600 font-semibold"
        whileHover={{ scale: 1.1 }}
        transition={{ type: 'spring', stiffness: 400, damping: 10 }}
      >
         <ArrowDownRight size={16} /> ${expenseAmount.toLocaleString()}
      </motion.span>
    </div>
  </motion.div>
);

export default IncomeSourceItem;
