// src/components/NewIncomeSourceDialog.jsx
import React, { useState } from 'react'

const NewIncomeSourceDialog = ({ isOpen, onClose, onSave }) => {
  const [newSourceName, setNewSourceName] = useState('')

  const handleSave = () => {
    const trimmedName = newSourceName.trim()
    if (trimmedName !== '') {
      onSave(trimmedName)
      setNewSourceName('')
      onClose()
    } else {
      alert('Please enter a valid name.')
    }
  }

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">Add New Income Source</h2>
        <p className="text-gray-600 mb-4">
          Enter the name of your new income source here. Click save when you're done.
        </p>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="name" className="text-right font-medium text-gray-700">
              Name
            </label>
            <input
              id="name"
              value={newSourceName}
              onChange={(e) => setNewSourceName(e.target.value)}
              className="col-span-3 border border-gray-300 rounded-lg p-2"
              placeholder="e.g., Freelancing"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white hover:bg-gray-600 py-2 px-4 rounded-lg mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="bg-blue-600 text-white hover:bg-blue-700 py-2 px-4 rounded-lg"
          >
            Save Income Source
          </button>
        </div>
      </div>
    </div>
  )
}

export default NewIncomeSourceDialog
