import React, { useState } from 'react'
import { ArrowRight, BarChart2, Briefcase, DollarSign, Globe, Menu, X } from "lucide-react"

const Button = ({ children, className, ...props }) => (
  <button className={`px-4 py-2 rounded-md ${className}`} {...props}>
    {children}
  </button>
)

const Input = ({ className, ...props }) => (
  <input className={`px-3 py-2 border rounded-md ${className}`} {...props} />
)

const Link = ({ children, ...props }) => (
  <a {...props}>{children}</a>
)

export default function LandingPage() {

  return (
    <div className="flex flex-col min-h-screen w-screen">
      <header className="px-4 lg:px-6 h-14 flex items-center justify-center">
        <div className="container flex items-center justify-between">
          <Link className="flex items-center justify-center" href="#">
            <Briefcase className="h-6 w-6 mr-2" />
            <span className="font-bold text-lg">Company Sheet</span>
          </Link>
          <nav className="gap-4 sm:gap-6 items-center">
            <Button className="bg-black text-white hover:bg-gray-800">
              Login
            </Button>
          </nav>
        </div>
      </header>
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                  Do you have multiple income streams and tired of using spreadsheets?
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl">
                  Say goodbye to complex spreadsheets and hello to Company Sheet - your all-in-one solution for managing multiple income sources with ease.
                </p>
              </div>
              <div className="w-full max-w-sm space-y-2">
                <form className="flex space-x-2">
                  <Input
                    className="max-w-lg flex-1"
                    placeholder="Enter your email"
                    type="email"
                  />
                  <Button type="submit" className="bg-black text-white hover:bg-gray-800">
                    Get Started
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="text-center mb-12 max-w-[800px] mx-auto">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl mb-4">From Financial Chaos to Clarity</h2>
              <p className="text-gray-500 md:text-lg">
                Imagine your financial life as a tangled web of income streams, expenses, and invoices. Now picture it transformed into a clear, organized masterpiece. That's the magic of Company Sheet.
              </p>
            </div>
            <div className="grid gap-8 md:grid-cols-2 lg:gap-12 items-center">
              <div className="space-y-4 bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-2xl font-bold text-red-500">Before Company Sheet</h3>
                <ul className="space-y-2">
                  <li className="flex items-center space-x-2">
                    <span className="text-red-500 text-xl">✗</span>
                    <span>Drowning in multiple spreadsheets</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <span className="text-red-500 text-xl">✗</span>
                    <span>Hours spent reconciling accounts</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <span className="text-red-500 text-xl">✗</span>
                    <span>Constant worry about missed invoices</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <span className="text-red-500 text-xl">✗</span>
                    <span>Financial stress keeping you up at night</span>
                  </li>
                </ul>
              </div>
              <div className="space-y-4 bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-2xl font-bold text-green-500">After Company Sheet</h3>
                <ul className="space-y-2">
                  <li className="flex items-center space-x-2">
                    <span className="text-green-500 text-xl">✓</span>
                    <span>All your finances in one intuitive dashboard</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <span className="text-green-500 text-xl">✓</span>
                    <span>Automated tracking and reconciliation</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <span className="text-green-500 text-xl">✓</span>
                    <span>Never miss an invoice or payment again</span>
                  </li>
                  <li className="flex items-center space-x-2">
                    <span className="text-green-500 text-xl">✓</span>
                    <span>Peace of mind and financial confidence</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl text-center mb-12">How Company Sheet Empowers You</h2>
            <div className="grid gap-10 sm:grid-cols-2 md:grid-cols-2">
              <div className="flex flex-col items-center space-y-3 text-center">
                <Briefcase className="h-12 w-12 text-blue-500" />
                <h3 className="text-xl font-bold">Multiple Income Source Tracking</h3>
                <p className="text-sm text-gray-500">Easily manage and monitor all your income streams in one intuitive dashboard. No more switching between multiple spreadsheets.</p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <DollarSign className="h-12 w-12 text-blue-500" />
                <h3 className="text-xl font-bold">Comprehensive Financial Overview</h3>
                <p className="text-sm text-gray-500">Get a clear picture of your total income, expenses, and profit across all sources. Make informed decisions with all your financial data in one place.</p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <BarChart2 className="h-12 w-12 text-blue-500" />
                <h3 className="text-xl font-bold">Income Analytics & Insights</h3>
                <p className="text-sm text-gray-500">Gain valuable insights with detailed reports and visualizations of your income streams. Understand your financial trends at a glance.</p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <Globe className="h-12 w-12 text-blue-500" />
                <h3 className="text-xl font-bold">Multi-Currency Support</h3>
                <p className="text-sm text-gray-500">Handle income and expenses in different currencies with ease. Perfect for freelancers working with international clients.</p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <div className="container flex flex-col sm:flex-row justify-between items-center">
          <p className="text-xs text-gray-500">© 2024 Company Sheet. All rights reserved.</p>
          <nav className="flex gap-4 sm:gap-6">
            <Link className="text-xs hover:underline underline-offset-4" href="#">
              Terms of Service
            </Link>
            <Link className="text-xs hover:underline underline-offset-4" href="#">
              Privacy
            </Link>
          </nav>
        </div>
      </footer>
    </div>
  )
}