// src/components/YearlyAnalysisChart.jsx
import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

const YearlyAnalysisChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={300}>
    <LineChart data={data} margin={{ top: 10, right: 30, left: 40, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" stroke="#e2e8f0" />
      <XAxis dataKey="name" />
      <YAxis
        tickFormatter={(value) => {
          if (value >= 1000000) return `$${(value / 1000000).toFixed(1)}M`
          if (value >= 1000) return `$${(value / 1000).toFixed(1)}k`
          return `$${value}`
        }}
      />
      <Tooltip
        formatter={(value, name) => [`$${value.toLocaleString()}`, name === 'income' ? 'Income' : 'Expenses']}
      />
      <Legend />
      <Line type="monotone" dataKey="income" stroke="#16a34a" strokeWidth={3} />
      <Line type="monotone" dataKey="expenses" stroke="#ef4444" strokeWidth={3} />
    </LineChart>
  </ResponsiveContainer>
)

export default YearlyAnalysisChart
