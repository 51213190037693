// src/components/RecentTransactions.js

import React, { useState, useMemo } from 'react';
import { Plus } from 'lucide-react';
import TransactionItem from './TransactionItem.js';
import PropTypes from 'prop-types';

const RecentTransactions = ({
  transactions,
  incomeSources,
  onAddTransaction,
  onEditTransaction,
}) => {
  // State for Sorting and Filtering
  const [sortOption, setSortOption] = useState('mostRecent'); // 'mostRecent' or 'highestAmount'
  const [filterOption, setFilterOption] = useState('all'); // 'all', 'income', 'expenses'

  // State for Visible Transactions
  const [visibleCount, setVisibleCount] = useState(10);

  // Handler Functions
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
    setVisibleCount(3); // Reset visible count on sort change
  };

  const handleFilterChange = (option) => {
    setFilterOption(option);
    setVisibleCount(3); // Reset visible count on filter change
  };

  const handleLoadMore = () => {
    setVisibleCount(processedTransactions.length);
  };

  // Memoized Processed Transactions
  const processedTransactions = useMemo(() => {
    let filtered = [...transactions];

    // Apply Filtering
    if (filterOption === 'income') {
      filtered = filtered.filter((t) => t.type === 'income');
    } else if (filterOption === 'expenses') {
      filtered = filtered.filter((t) => t.type === 'expense');
    }

    // Apply Sorting
    if (sortOption === 'mostRecent') {
      filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
    } else if (sortOption === 'highestAmount') {
      filtered.sort((a, b) => b.amount - a.amount);
    }

    return filtered;
  }, [transactions, sortOption, filterOption]);

  // Determine if "Load More" Button Should be Displayed
  const shouldShowLoadMore = processedTransactions.length > visibleCount;

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <h2 className="text-2xl font-bold">Recent Transactions</h2>
          <button
            onClick={onAddTransaction}
            className="p-2 bg-black text-white rounded-full hover:bg-black-700 transition ml-2"
            aria-label="Add Transaction"
          >
            <Plus className="h-5 w-5" />
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="sort" className="text-gray-700 font-semibold">
            Sort By:
          </label>
          <select
            id="sort"
            value={sortOption}
            onChange={handleSortChange}
            className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="mostRecent">Most Recent</option>
            <option value="highestAmount">Highest Amount</option>
          </select>
        </div>
      </div>

      {/* Sorting and Filtering Controls */}
      <div className="flex flex-col md:flex-row md:justify-between mb-6 mt-8 space-y-4 md:space-y-0">
        {/* Filter Tabs */}
        <div className="flex justify-center mx-auto">
          <div className="flex space-x-2 bg-gray-100 rounded-full p-1">
            {['all', 'income', 'expenses'].map((option) => (
              <button
                key={option}
                onClick={() => handleFilterChange(option)}
                className={`px-4 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-black ${
                  filterOption === option
                    ? 'bg-black text-white font-semibold shadow'
                    : 'text-gray-700 hover:bg-gray-300'
                }`}
                aria-pressed={filterOption === option}
              >
                {option === 'all'
                  ? 'All'
                  : option === 'income'
                  ? 'Income Only'
                  : 'Expenses Only'}
              </button>
            ))}
          </div>
        </div>

        {/* Sort Dropdown */}
        {/* (Already included in the Header) */}
      </div>

      {/* Description */}
      <p className="text-gray-600 mb-6">Your latest financial activities</p>

      {/* Transactions List */}
      <div className="space-y-4 overflow-y-auto">
        {processedTransactions.slice(0, visibleCount).length > 0 ? (
          processedTransactions.slice(0, visibleCount).map((transaction) => (
            <TransactionItem
              key={transaction.id}
              {...transaction}
              incomeSourceName={
                transaction.incomeSourceId
                  ? incomeSources.find((source) => source.id === transaction.incomeSourceId)?.name
                  : 'Uncategorized'
              }
              onClick={() => onEditTransaction(transaction)}
            />
          ))
        ) : (
          <p className="text-gray-500">No transactions available.</p>
        )}
      </div>

      {/* Load More Button */}
      {shouldShowLoadMore && (
        <div className="mt-4 flex justify-center">
          <button
            onClick={handleLoadMore}
            className="px-4 py-2 bg-black text-white rounded-full hover:bg-gray-800 transition"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

// PropTypes Validation
RecentTransactions.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      incomeSourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      type: PropTypes.oneOf(['income', 'expense']).isRequired,
      date: PropTypes.string.isRequired,
    })
  ).isRequired,
  incomeSources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      total_amount_this_year: PropTypes.number.isRequired,
      total_expenses_this_year: PropTypes.number.isRequired,
    })
  ).isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  onEditTransaction: PropTypes.func.isRequired,
};

export default RecentTransactions;
