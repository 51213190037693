// Dashboard.js

import React, { useState, useEffect } from 'react';
import { Settings, Plus } from 'lucide-react';
import IncomeSourceItem from './components/IncomeSourceItem.js';
import NewIncomeSourceDialog from './components/NewIncomeSourceDialog.js';
import NewTransactionDialog from './components/NewTransactionDialog.js';
import YearlyAnalysisChart from './components/YearlyAnalysisChart.js';
import RecentTransactions from './components/RecentTransactions.js'; // New Import
import TransactionItem from './components/TransactionItem.js'; // May not be needed if handled in RecentTransactions

const Dashboard = () => {
  // State Definitions
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTransactionDialogOpen, setIsTransactionDialogOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState('Total');
  const [editingTransaction, setEditingTransaction] = useState(null);

  // Initial Yearly Data (can be dynamic based on current year)
  const initialYearlyData = [
    { name: 'Jan', income: 0, expenses: 0 },
    { name: 'Feb', income: 0, expenses: 0 },
    { name: 'Mar', income: 0, expenses: 0 },
    { name: 'Apr', income: 0, expenses: 0 },
    { name: 'May', income: 0, expenses: 0 },
    { name: 'Jun', income: 0, expenses: 0 },
    { name: 'Jul', income: 0, expenses: 0 },
    { name: 'Aug', income: 0, expenses: 0 },
    { name: 'Sep', income: 0, expenses: 0 },
    { name: 'Oct', income: 0, expenses: 0 },
    { name: 'Nov', income: 0, expenses: 0 },
    { name: 'Dec', income: 0, expenses: 0 },
  ];

  const [yearlyData, setYearlyData] = useState(initialYearlyData);
  const [incomeSources, setIncomeSources] = useState([
    {
      id: 1,
      name: 'Web Development',
      total_amount_this_year: 0,
      total_expenses_this_year: 0,
    },
    {
      id: 2,
      name: 'Graphic Design',
      total_amount_this_year: 0,
      total_expenses_this_year: 0,
    },
    {
      id: 3,
      name: 'Content Writing',
      total_amount_this_year: 0,
      total_expenses_this_year: 0,
    },
  ]);

  const [transactions, setTransactions] = useState([
    {
      id: 1,
      incomeSourceId: 1,
      description: 'Client Payment',
      amount: 125000,
      type: 'income',
      date: '2024-09-15', // ISO Date String
    },
    {
      id: 2, // Changed from 1 to 2
      incomeSourceId: 1,
      description: 'Client Payment',
      amount: 12500,
      type: 'expense',
      date: '2024-08-15', // ISO Date String
    },
    {
      id: 3,
      incomeSourceId: 2,
      description: 'Design Project',
      amount: 3000,
      type: 'income',
      date: '2024-08-10',
    },
    {
      id: 4, // Changed from 3 to 4
      incomeSourceId: 3,
      description: 'doing something',
      amount: 2000,
      type: 'income',
      date: '2024-07-05',
    },
    {
      id: 5, // Changed from 3 to 5
      incomeSourceId: 3,
      description: '420 seo',
      amount: 200,
      type: 'expense',
      date: '2024-07-02',
    },
    {
      id: 6, // Changed from 3 to 6
      incomeSourceId: 3,
      description: 'Article Writing',
      amount: 23400,
      type: 'income',
      date: '2024-07-05',
    },
    {
      id: 7, // Changed from 3 to 7
      incomeSourceId: 3,
      description: 'Article Writing',
      amount: 2000,
      type: 'expense',
      date: '2024-05-05',
    },
  ]);

  // Effect to Recalculate Income/Expenses Sources Totals
  useEffect(() => {
    setIncomeSources((prevSources) =>
      prevSources.map((source) => {
        const currentYear = new Date().getFullYear();

        // Calculate total income for the source
        const totalIncome = transactions
          .filter(
            (t) =>
              t.incomeSourceId === source.id &&
              t.type === 'income' &&
              new Date(t.date).getFullYear() === currentYear
          )
          .reduce((sum, t) => sum + t.amount, 0);

        // Calculate total expenses for the source
        const totalExpenses = transactions
          .filter(
            (t) =>
              t.incomeSourceId === source.id &&
              t.type === 'expense' &&
              new Date(t.date).getFullYear() === currentYear
          )
          .reduce((sum, t) => sum + t.amount, 0);

        return {
          ...source,
          total_amount_this_year: totalIncome,
          total_expenses_this_year: totalExpenses,
        };
      })
    );
  }, [transactions]);

  // Effect to Recalculate Yearly Data based on selected income source
  useEffect(() => {
    // Reset yearly data
    const updatedYearlyData = initialYearlyData.map((month) => ({
      ...month,
      income: 0,
      expenses: 0,
    }));

    const filteredTransactions = transactions.filter((transaction) => {
      if (selectedSource === 'Total') return true;
      if (selectedSource === 'uncategorized')
        return transaction.incomeSourceId === null || transaction.incomeSourceId === undefined;
      return transaction.incomeSourceId === selectedSource;
    });

    filteredTransactions.forEach((transaction) => {
      const transactionDate = new Date(transaction.date);
      const currentYear = new Date().getFullYear();
      if (transactionDate.getFullYear() === currentYear) {
        const monthIndex = transactionDate.getMonth(); // 0-based index
        if (transaction.type === 'income') {
          updatedYearlyData[monthIndex].income += transaction.amount;
        } else if (transaction.type === 'expense') {
          updatedYearlyData[monthIndex].expenses += transaction.amount;
        }
      }
    });

    setYearlyData(updatedYearlyData);
  }, [transactions, selectedSource]);

  // Function to Add a New Income Source
  const handleSaveIncomeSource = (newSourceName) => {
    const exists = incomeSources.find(
      (source) => source.name.toLowerCase() === newSourceName.toLowerCase()
    );
    if (!exists) {
      const newId =
        incomeSources.length > 0 ? Math.max(...incomeSources.map((s) => s.id)) + 1 : 1;
      setIncomeSources((prevSources) => [
        ...prevSources,
        {
          id: newId,
          name: newSourceName,
          total_amount_this_year: 0,
          total_expenses_this_year: 0, // Initialize expenses
        },
      ]);
    } else {
      alert('Income source already exists.');
    }
  };

  // Function to Add or Edit a Transaction
  const handleSaveTransaction = (transaction) => {
    console.log('transaction: ', transaction)
    setTransactions((prevTransactions) => {
      if (transaction.id) {
        // Edit existing transaction
        return prevTransactions.map((t) => (t.id === transaction.id ? transaction : t));
      } else {
        // Add new transaction
        return [...prevTransactions, { ...transaction, id: Date.now() }];
      }
    });
    setEditingTransaction(null);
    setIsTransactionDialogOpen(false);
  };

  // Function to Handle Editing a Transaction
  const handleEditTransaction = (transaction) => {
    setEditingTransaction(transaction);
    setIsTransactionDialogOpen(true);
  };

  // Calculate Total Income (All Income Transactions)
  const totalIncome = transactions
    .filter((t) => t.type === 'income')
    .reduce((sum, t) => sum + t.amount, 0);

  // Calculate Total Expenses (All Expense Transactions)
  const totalExpenses = transactions
    .filter((t) => t.type === 'expense')
    .reduce((sum, t) => sum + t.amount, 0);

  // Calculate Total Uncategorized Income
  const totalUncategorized = transactions
    .filter((t) => (t.incomeSourceId === null || t.incomeSourceId === undefined) && t.type === 'income')
    .reduce((sum, t) => sum + t.amount, 0);

  // Calculate Total Uncategorized Expenses
  const totalUncategorizedExpenses = transactions
    .filter(
      (t) =>
        (t.incomeSourceId === null || t.incomeSourceId === undefined) &&
        t.type === 'expense' &&
        new Date(t.date).getFullYear() === new Date().getFullYear()
    )
    .reduce((sum, t) => sum + t.amount, 0);

  // Prepare Income Sources for Display (Include "Uncategorized" if applicable)
  const displayIncomeSources = [...incomeSources];

  if (totalUncategorized > 0 || totalUncategorizedExpenses > 0) {
    displayIncomeSources.push({
      id: 'uncategorized', // Unique identifier for Uncategorized
      name: 'Uncategorized',
      total_amount_this_year: totalUncategorized,
      total_expenses_this_year: totalUncategorizedExpenses,
    });
  }

  // Filtered and Sorted Transactions Based on Selected Income Source
  const filteredTransactions = transactions
    .filter((t) => {
      if (selectedSource === 'Total') return true;
      if (selectedSource === 'uncategorized')
        return t.incomeSourceId === null || t.incomeSourceId === undefined;
      return t.incomeSourceId === selectedSource;
    })
    .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort descending by date

  return (
    <div className="min-h-screen p-10" style={{ background: '#f6f2ef' }}>
      <div className="max-w-8xl mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-10">
          <h1 className="text-4xl font-bold text-gray-900">My Company Sheet</h1>
          <button className="p-2 bg-white border border-gray-300 rounded-full shadow hover:bg-gray-100">
            <Settings className="h-6 w-6 text-gray-600" />
          </button>
        </div>

        <div className="flex gap-4">
          {/* Income Sources Sidebar */}
          <div className="w-2/6 bg-white p-6 rounded-lg shadow-lg sticky top-0 h-fit">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Income Sources</h2>
              <button
                onClick={() => setIsDialogOpen(true)}
                className="p-2 bg-black text-white rounded-full transition"
              >
                <Plus className="h-5 w-5" />
              </button>
            </div>
            <p className="text-gray-600 mb-6">Your current income streams</p>
            <div className="space-y-4">
              {/* "Total" Income Source */}
              <IncomeSourceItem
                name="All"
                incomeAmount={totalIncome}
                expenseAmount={totalExpenses}
                onSelect={() => setSelectedSource('Total')}
                isSelected={selectedSource === 'Total'}
              />
              {/* Specific Income Sources */}
              {displayIncomeSources.map((source) => (
                <IncomeSourceItem
                  key={source.id}
                  name={source.name}
                  incomeAmount={source.total_amount_this_year}
                  expenseAmount={source.total_expenses_this_year}
                  onSelect={() => setSelectedSource(source.id)}
                  isSelected={selectedSource === source.id}
                />
              ))}
            </div>
          </div>

          {/* Add New Income Source Dialog */}
          <NewIncomeSourceDialog
            isOpen={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            onSave={handleSaveIncomeSource}
          />

          {/* Main Content Area */}
          <div className="flex-1 space-y-8">
            {/* Yearly Analysis Section */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold mb-2">Yearly Analysis</h2>
              <p className="text-gray-600 mb-6">Income vs Expenses this year</p>
              <YearlyAnalysisChart data={yearlyData} />
            </div>

            {/* Recent Transactions Section */}
            <RecentTransactions
              transactions={filteredTransactions}
              incomeSources={incomeSources}
              onAddTransaction={() => setIsTransactionDialogOpen(true)}
              onEditTransaction={handleEditTransaction}
            />
          </div>
        </div>

        {/* Add/Edit Transaction Dialog */}
        <NewTransactionDialog
          isOpen={isTransactionDialogOpen}
          onClose={() => {
            setEditingTransaction(null);
            setIsTransactionDialogOpen(false);
          }}
          onSave={handleSaveTransaction}
          incomeSources={incomeSources}
          defaultSource={selectedSource === 'Total' ? null : selectedSource}
          editingTransaction={editingTransaction}
        />
      </div>
    </div>
  );
};

export default Dashboard;
