import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon, DollarSign, Upload, X } from 'lucide-react';

const currencies = [
  { code: 'USD', symbol: '$' },
  { code: 'EUR', symbol: '€' },
  { code: 'GBP', symbol: '£' },
  { code: 'JPY', symbol: '¥' },
];

export default function NewTransactionDialog({
  isOpen,
  onClose,
  onSave,
  incomeSources,
  defaultSource,
  editingTransaction,
}) {
  const [transactionData, setTransactionData] = useState(
    editingTransaction || {
      description: '',
      incomeSourceId: defaultSource === 'Total' ? null : defaultSource,
      date: new Date(),
      amount: '',
      type: 'income',
      currency: 'USD',
      fileUrl: '',
      fileName: '',
    }
  );

  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (editingTransaction) {
      setTransactionData(editingTransaction);
    } else {
      setTransactionData({
        description: '',
        incomeSourceId: defaultSource === 'Total' ? null : defaultSource,
        date: new Date(),
        amount: '',
        type: 'income',
        currency: 'USD',
        fileUrl: '',
        fileName: '',
      });
    }
  }, [editingTransaction, defaultSource]);

  const handleChange = (field, value) => {
    setTransactionData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Limit file size to 2MB
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSize) {
        alert('File size exceeds 2MB limit.');
        return;
      }

      setIsUploading(true);

      // Create FormData
      const formData = new FormData();
      formData.append('file', file);

      try {
        // Upload file to the server
        const response = await fetch('http://localhost:5000/upload', {
          method: 'POST',
          body: formData,
        });

        const data = await response.json();

        if (response.ok) {
          handleChange('fileUrl', data.fileUrl);
          handleChange('fileName', file.name);
        } else {
          alert('File upload failed');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('An error occurred while uploading the file.');
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleFileRemove = async () => {
    if (!transactionData.fileName) return;

    console.log(transactionData.fileUrl)

    try {
      const response = await fetch('http://localhost:5000/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName: transactionData.fileName, fileUrl: transactionData.fileUrl }),
      });

      const data = await response.json();

      if (response.ok) {
        handleChange('fileUrl', '');
        handleChange('fileName', '');
      } else {
        alert('Failed to delete file.');
      }
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      // Trigger the same validation and upload as handleFileChange
      const event = { target: { files: [file] } };
      handleFileChange(event);
    }
  };

  const handleSave = () => {
    const {
      description,
      incomeSourceId,
      amount,
      type,
      fileUrl,
      fileName,
    } = transactionData;
    if (description.trim() !== '' && parseFloat(amount) > 0) {
      const transactionToSave = {
        ...transactionData,
        amount: parseFloat(amount),
        incomeSourceId: incomeSourceId
          ? parseInt(incomeSourceId, 10)
          : null,
        fileUrl,
        fileName,
      };
      onSave(transactionToSave);
      onClose();
    } else {
      alert('Please enter a valid description and amount.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl p-6">
        <h2 className="text-2xl font-bold mb-4">
          {editingTransaction ? 'Edit Transaction' : 'Add New Transaction'}
        </h2>
        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-4">
            {/* Amount Input */}
            <div className="space-y-2">
              <label
                htmlFor="amount"
                className="block text-sm font-medium text-gray-700"
              >
                Amount
              </label>
              <div className="flex">
                <select
                  value={transactionData.currency}
                  onChange={(e) => handleChange('currency', e.target.value)}
                  className="p-2 border border-gray-300 rounded-l-md"
                >
                  {currencies.map((currency) => (
                    <option key={currency.code} value={currency.code}>
                      {currency.symbol} {currency.code}
                    </option>
                  ))}
                </select>
                <div className="relative flex-1">
                  <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    id="amount"
                    type="number"
                    value={transactionData.amount}
                    onChange={(e) =>
                      handleChange('amount', e.target.value)
                    }
                    className="pl-10 border border-gray-300 rounded-r-md w-full p-2"
                    placeholder="0.00"
                  />
                </div>
              </div>
            </div>
            {/* Description Input */}
            <div className="space-y-2">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <textarea
                id="description"
                value={transactionData.description}
                onChange={(e) =>
                  handleChange('description', e.target.value)
                }
                placeholder="e.g., Client Payment for Project X"
                className="w-full border border-gray-300 rounded-md p-2"
                rows="4"
              />
            </div>
            {/* Income Source Select */}
            <div className="space-y-2">
              <label
                htmlFor="incomeSourceId"
                className="block text-sm font-medium text-gray-700"
              >
                Income Source
              </label>
              <select
                value={
                  transactionData.incomeSourceId !== null
                    ? transactionData.incomeSourceId.toString()
                    : ''
                }
                onChange={(e) =>
                  handleChange(
                    'incomeSourceId',
                    e.target.value === ''
                      ? null
                      : parseInt(e.target.value, 10)
                  )
                }
                className="w-full p-2 border border-gray-300 rounded-md"
              >
                <option value="">Uncategorized</option>
                {incomeSources.map((source) => (
                  <option key={source.id} value={source.id.toString()}>
                    {source.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="space-y-4">
            {/* Date Input */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Date
              </label>
              <div className="relative">
                <CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="date"
                  value={format(transactionData.date, 'yyyy-MM-dd')}
                  onChange={(e) =>
                    handleChange('date', new Date(e.target.value))
                  }
                  className="pl-10 w-full border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>
            {/* Type Selection */}
            <div className="space-y-2">
              <label
                htmlFor="type"
                className="block text-sm font-medium text-gray-700"
              >
                Type
              </label>
              <div className="flex space-x-4">
                <button
                  type="button"
                  className={`w-full p-2 text-center rounded-md transition ${
                    transactionData.type === 'income'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-200 text-gray-800'
                  }`}
                  onClick={() => handleChange('type', 'income')}
                >
                  Income
                </button>
                <button
                  type="button"
                  className={`w-full p-2 text-center rounded-md transition ${
                    transactionData.type === 'expense'
                      ? 'bg-red-100 text-red-800'
                      : 'bg-gray-200 text-gray-800'
                  }`}
                  onClick={() => handleChange('type', 'expense')}
                >
                  Expense
                </button>
              </div>
            </div>
            {/* File Upload */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Attachment
              </label>
              <div
                className="border-2 border-dashed rounded-md p-4 text-center cursor-pointer relative"
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  id="file"
                  className="hidden"
                  onChange={handleFileChange}
                />
                {!transactionData.fileUrl && !isUploading && (
                  <label htmlFor="file" className="cursor-pointer">
                    <Upload className="mx-auto h-12 w-12 text-gray-400" />
                    <p className="mt-2 text-sm text-gray-500">
                      Drag and drop a file here, or click to select a
                      file (Max size: 2MB)
                    </p>
                  </label>
                )}
                {isUploading && (
                  <div className="flex flex-col items-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                    <p className="text-sm text-gray-500">Uploading...</p>
                  </div>
                )}
                {transactionData.fileUrl && !isUploading && (
                  <div className="flex items-center justify-center space-x-2">
                    <a
                      href={transactionData.fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      {transactionData.fileName}
                    </a>
                    <button
                      type="button"
                      onClick={handleFileRemove}
                      className="text-red-500 hover:text-red-700"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                )}
              </div>
              {/* Loader Styles */}
              <style jsx>{`
                .loader {
                  border-top-color: #3498db;
                  animation: spin 1s linear infinite;
                }
                @keyframes spin {
                  to {
                    transform: rotate(360deg);
                  }
                }
              `}</style>
            </div>
          </div>
        </div>
        {/* Action Buttons */}
        <div className="mt-6 flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white rounded-md"
          >
            Save Transaction
          </button>
        </div>
      </div>
    </div>
  );
}
