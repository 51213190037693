"use client"

import React from 'react'
import { ArrowUpRight, ArrowDownRight } from 'lucide-react'
import { motion } from 'framer-motion'

const TransactionItem = ({ description, amount, type, date, onClick, incomeSourceName }) => (
  <motion.div
    className="flex flex-col py-2 px-4 border-b border-gray-200 last:border-b-0 cursor-pointer"
    onClick={onClick}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3 }}
    whileHover={{ scale: 1.02, backgroundColor: 'rgba(0, 0, 0, 0.02)' }}
    whileTap={{ scale: 0.98 }}
  >
    <motion.div className="flex justify-between items-center">
      <motion.span
        className="font-semibold text-gray-900"
        whileHover={{ x: 5 }}
        transition={{ type: 'spring', stiffness: 300 }}
      >
        {description}
      </motion.span>
      <motion.div
        className={`flex items-center ${type === 'income' ? 'text-green-600' : 'text-red-600'}`}
        whileHover={{ scale: 1.1 }}
        transition={{ type: 'spring', stiffness: 400, damping: 10 }}
      >
        {type === 'income' ? <ArrowUpRight size={16} /> : <ArrowDownRight size={16} />}
        <span className="ml-1 font-semibold">${amount.toLocaleString()}</span>
      </motion.div>
    </motion.div>
    <div className="flex justify-between items-center">
      <motion.span
        className="text-sm text-gray-500 mt-1"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        {new Date(date).toLocaleDateString()}
      </motion.span>
      {incomeSourceName && (
        <motion.span
          className="text-sm text-gray-500 mt-1"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          {incomeSourceName}
        </motion.span>
      )}
    </div>
  </motion.div>
)

export default TransactionItem