// src/App.js
import React, { useState } from 'react'; // Import useState
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// Unlogged components
import Home from './Unlogged/HomePage';
import Login from './Unlogged/Login';

// Logged components
import Dashboard from './Logged/Dashboard';

function App() {
  // Initialize isLoggedIn as a state variable
  const [isLoggedIn, setIsLoggedIn] = useState(Cookies.get('isLoggedIn'));

  return (
    <Router>
      <Routes>
        {/* Redirect logged-in users to the dashboard */}
        {isLoggedIn ? (
          <>
            <Route path="/dashboard" element={<Dashboard setIsLoggedIn={setIsLoggedIn} />} />
            {/* Redirect any unlogged routes to the dashboard */}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            {/* Redirect any unknown routes to the home page */}
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
